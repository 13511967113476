import "./BackgroundContainer.scss";
import { useDispatch, useSelector } from "react-redux";

import { backgrounds } from "../../../lib/background/backgrounds";
import ButtonGroup from "../../ButtonGroup/ButtonGroup";
import { setBackground, SettingsState } from "../../../store/settings";
import { DLC_MAPPING } from "../../../types/GameTypes";

const BackgroundContainer = () => {
  const { background } = useSelector(
    (state: any) => state.settings
  ) as SettingsState;

  const dispatch = useDispatch();

  return (
    <>
      {Object.values(DLC_MAPPING).map((dlc, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
              marginBottom: 20,
            }}
          >
            <img
              height={50}
              style={{ objectFit: "contain" }}
              src={`assets/${dlc.path}/logo.png`}
              alt={"logo of " + dlc.name}
            />
            <div style={{ display: "flex" }}>
              <ButtonGroup
                values={["none", ...Object.keys(backgrounds[dlc.id])]}
                selected={
                  !Object.keys(backgrounds[dlc.id]).includes(
                    background?.id ?? ""
                  )
                    ? "none"
                    : background?.id
                }
                onRender={(key: string) => {
                  if (key === "none") {
                    return <div>None</div>;
                  }
                  return (
                    <img
                      src={"assets/" + backgrounds[dlc.id][key].icon}
                      alt={key}
                    />
                  );
                }}
                onClick={(key: string) => {
                  dispatch(setBackground(backgrounds[dlc.id][key]));
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default BackgroundContainer;
