import "./ItemsContainer.scss";
import { Item, ItemManager } from "../../../lib/appearance/item_manager";
import ItemElement from "../../ItemElement/ItemElement";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addAppearance } from "../../../store/avatar";

type ItemsContainerProps = {
  selected?: string[];
};

const ItemsContainer = ({ selected = [] }: ItemsContainerProps) => {
  const dispatch = useDispatch();

  const groups: { [key: string]: Item[] } = ItemManager.instance.groups;

  const [filterText, setFilterText] = useState<string>("");

  const filterItems = (item: Item): boolean => {
    return item.name.toLowerCase().includes(filterText.toLowerCase());
  };

  return (
    <>
      <div className="top-bar">
        <input
          className="filter-input"
          type="search"
          placeholder="Search"
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
        />
      </div>
      <div className="items-container">
        {Object.values(groups).map((group, index) => {
          const name = Object.keys(groups)[index];
          const amount = group.filter((item) => filterItems(item)).length;
          return (
            amount > 0 && (
              <div
                style={{ display: "flex", flexDirection: "column" }}
                key={index}
              >
                <span className="catergory-name">{name}</span>
                <div className="items-container">
                  {group
                    .filter((item) => filterItems(item))
                    .map((item) => {
                      return (
                        <ItemElement
                          item={item}
                          groupName={name}
                          key={item.id}
                          selected={selected.includes(item.id)}
                          onClick={() => {
                            dispatch(addAppearance(item.id));
                          }}
                        >
                          {item.name}
                        </ItemElement>
                      );
                    })}
                </div>
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

export default ItemsContainer;
