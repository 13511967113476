export class StorageManager {
  private constructor() {}

  public static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public static get(key: string): any {
    const getKey = localStorage.getItem(key);
    if (getKey !== "undefined" && getKey !== null) {
      return JSON.parse(getKey);
    }
    return null;
  }
}
