import { createSlice } from "@reduxjs/toolkit";

export interface AvatarState {
  appearance: string[];
  undo: string[][];
  redo: string[][];
}

const initialState = {
  appearance: [],
  undo: [],
  redo: [],
} satisfies AvatarState as AvatarState;

export const avatarSlice = createSlice({
  name: "avatar",
  initialState,
  reducers: {
    undo: (state) => {
      const last = state.undo.pop();
      if (last) {
        state.redo.push(state.appearance);
        state.appearance = last;
      }
    },
    redo: (state) => {
      const last = state.redo.pop();
      if (last) {
        state.undo.push(state.appearance);
        state.appearance = last;
      }
    },
    add: (state, action) => {
      if (state.appearance.length > 0) state.undo.push(state.appearance);
      state.redo = [];
      state.appearance = action.payload;
    },
    addWithoutSave: (state, action) => {
      state.appearance = action.payload;
    },
    addAppearance: (state, { payload }) => {
      if (state.appearance.includes(payload)) {
        state.appearance = state.appearance.filter((a) => a !== payload);
      } else state.appearance.push(payload);
    },
    addCosmetic: (state, { payload }) => {
      if (state.appearance.includes(payload)) {
        state.appearance = state.appearance.filter((a) => a !== payload);
      } else state.appearance.push(payload);
    },
    clearActive: (state) => {
      if (state.appearance.length > 0) state.undo.push(state.appearance);
      state.appearance = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  undo,
  redo,
  add,
  addWithoutSave,
  addAppearance,
  addCosmetic,
  clearActive,
} = avatarSlice.actions;

export default avatarSlice.reducer;
