import "./ScrollSelector.scss";
import { useState } from "react";

type Item = { key: string; value: string; index: number };

type ScrollSelectorProps = {
  items: { key: string; value: string; index: number }[];
  selected?: number;
  onSelect: (selected: Item) => void;
};

const ScrollSelector = ({ items, selected, onSelect }: ScrollSelectorProps) => {
  const [selectedItem, setSelectedItem] = useState<number>(
    selected ?? items[0].index
  );
  return (
    <div className="scroll-selector">
      {items.map((item: Item, index) => (
        <div
          key={index}
          className={`scroll-selector-item ${
            selectedItem === item.index ? "selected" : ""
          }`}
          onClick={() => {
            setSelectedItem(item.index);
            onSelect(item);
          }}
        >
          {item.value}
        </div>
      ))}
    </div>
  );
};

export default ScrollSelector;
