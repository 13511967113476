import { cosmetics } from "./data";
import { Item } from "./item_manager";

export class CosmeticManager {
  private static _instance: CosmeticManager;
  public groups: { [key: string]: Item[] } = {};
  public items: Item[];

  private constructor() {
    this.items = this.getItems();
  }

  public setItems = (): void => {
    this.items = this.getItems();
  };

  public getItems = (): Item[] => {
    return Object.values(cosmetics).map((item) => new Item(item));
  };

  public static get instance(): CosmeticManager {
    if (!this._instance) {
      this._instance = new CosmeticManager();
    }
    return this._instance;
  }

  public getItemById(id: string): Item | undefined {
    return this.items.find((item) => item.id === id);
  }
}
