import "./CosmeticContainer.scss";
import { CosmeticManager } from "../../../lib/appearance/cosmetic_manager";
import { Item } from "../../../lib/appearance/item_manager";
import ItemElement from "../../ItemElement/ItemElement";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addCosmetic } from "../../../store/avatar";

type CosmeticContainerProps = {
  selected?: string[];
};

const CosmeticContainer = ({ selected = [] }: CosmeticContainerProps) => {
  const dispatch = useDispatch();

  const items: Item[] = CosmeticManager.instance.items;

  const [filterText, setFilterText] = useState<string>("");

  const filterItems = (item: Item): boolean => {
    return item.name.toLowerCase().includes(filterText.toLowerCase());
  };

  return (
    <>
      <div className="top-bar">
        <input
          className="filter-input"
          type="search"
          placeholder="Search"
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
        />
      </div>
      <div className="cosmetic-container">
        {items
          .filter((item) => filterItems(item))
          .map((item) => {
            return (
              <ItemElement
                item={item}
                key={item.id}
                selected={selected.includes(item.id)}
                onClick={() => {
                  dispatch(addCosmetic(item.id));
                }}
              >
                {item.name}
              </ItemElement>
            );
          })}
      </div>
    </>
  );
};

export default CosmeticContainer;
