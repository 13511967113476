import { useSelector } from "react-redux";
import "./ItemElement.scss";
import { ItemMode, SettingsState } from "../../store/settings";
import { AudioManager } from "../../lib/audio/audioManager";
import { Appearance, Item } from "../../lib/appearance/item_manager";
import { DLC_MAPPING } from "../../types/GameTypes";

function ItemElement({
  item,
  selected = true,
  onClick = () => {},
  itemSettings = { imageMode: ItemMode.Item, showText: true },
  groupName = "",
  ...props
}: {
  item: Item;
  selected?: boolean;
  onClick?: Function;
  groupName?: string;
  [key: string]: any;
}) {
  const { itemMode, showLabels }: SettingsState = useSelector(
    (state: any) => state.settings
  );

  const appearanceItem: Appearance[] = item.appearance;

  return (
    <div
      onClick={() => {
        onClick(item.id);
        AudioManager.click();
      }}
      className={"item-element-container " + (selected ? "selected" : "")}
      {...props}
    >
      <div className="interaction-element"></div>
      <div
        className="image-container"
        style={{
          top: itemMode === ItemMode.Equiped ? 20 : 20,
        }}
      >
        {itemMode === ItemMode.Equiped && (
          <img
            className="base"
            key={item.name + "_base"}
            alt="base"
            src={`assets/${DLC_MAPPING[item.dlc].path}/base.png`}
            style={{
              opacity: 0.7,
            }}
          />
        )}
        {appearanceItem.map((appearanceItem: Appearance) => {
          return (
            <img
              key={appearanceItem.path}
              src={itemMode === ItemMode.Item ? item.icon : appearanceItem.path}
              alt={item.name}
              style={{
                width: itemMode === ItemMode.Item ? 50 : 100,
                height: itemMode === ItemMode.Item ? 50 : 100,
                position: "absolute",
                alignSelf: "center",
                zIndex: appearanceItem.depth,
              }}
            />
          );
        })}
      </div>
      {showLabels && (
        <span
          style={{
            fontWeight: "bold",
            marginTop: itemMode === ItemMode.Item ? 35 : 35,
            color: DLC_MAPPING[item.dlc].fontColor,
          }}
        >
          {item.name}
        </span>
      )}
    </div>
  );
}

export default ItemElement;
