import { APPEARANCE_OBJECT, ITEM_OBJECT } from "../../types/AppearanceTypes";

export const refineCharacterItems = (characters: {
  [key: string]: ITEM_OBJECT;
}) =>
  Object.keys(characters).reduce((p: { [key: string]: ITEM_OBJECT }, c) => {
    const obj: ITEM_OBJECT = characters[c];
    if (Array.isArray(obj.appearance)) {
      return {
        ...p,
        ...obj.appearance.reduce(
          (p_app: any, appearance: APPEARANCE_OBJECT) => {
            if (!appearance?.id) return p_app;
            if (p_app?.[appearance.id])
              return {
                ...p_app,
                [appearance.id]: {
                  ...p_app[appearance.id],
                  appearance: [appearance, p_app[appearance.id].appearance],
                },
              };
            return {
              ...p_app,
              [appearance.id]: {
                id: appearance.id,
                name: appearance.name,
                icon: obj.icon,
                category: obj.category,
                appearance: appearance,
              },
            };
          },
          {}
        ),
      };
    }
    return { ...p, [c]: obj };
  }, {});
