import { DLC, DLC_MAPPING } from "../../types/GameTypes";

const enum backgrounds_enum {
  base_tiles_1 = "base_tiles_1",
  base_tiles_2 = "base_tiles_2",
  base_tiles_3 = "base_tiles_3",
  base_tiles_4 = "base_tiles_4",
  base_tiles_5 = "base_tiles_5",
  base_tiles_6 = "base_tiles_6",
  bg_abyss = "bg_abyss",
  bg_ocean = "bg_ocean",
  bg_shipwreck = "bg_shipwreck",
}

export type BackgroundObject = {
  id: string;
  image?: string;
  icon?: string;
  name?: string;
};

export type Backgrounds = { [key in DLC]: { [id: string]: BackgroundObject } };

const prefixes = {
  [DLC.BaseGame]: DLC_MAPPING.BaseGame.path + "/background",
  [DLC.AbyssalTerrors]: DLC_MAPPING.AbyssalTerrors.path + "/background",
};

const backgrounds: Backgrounds = {
  [DLC.BaseGame]: {
    [backgrounds_enum.base_tiles_1]: {
      id: backgrounds_enum.base_tiles_1,
      image: prefixes[DLC.BaseGame] + "/tiles_1.png",
      icon: prefixes[DLC.BaseGame] + "/tiles_1_icon.png",
    },
    [backgrounds_enum.base_tiles_2]: {
      id: backgrounds_enum.base_tiles_2,
      image: prefixes[DLC.BaseGame] + "/tiles_2.png",
      icon: prefixes[DLC.BaseGame] + "/tiles_2_icon.png",
    },
    [backgrounds_enum.base_tiles_3]: {
      id: backgrounds_enum.base_tiles_3,
      image: prefixes[DLC.BaseGame] + "/tiles_3.png",
      icon: prefixes[DLC.BaseGame] + "/tiles_3_icon.png",
    },
    [backgrounds_enum.base_tiles_4]: {
      id: backgrounds_enum.base_tiles_4,
      image: prefixes[DLC.BaseGame] + "/tiles_4.png",
      icon: prefixes[DLC.BaseGame] + "/tiles_4_icon.png",
    },
    [backgrounds_enum.base_tiles_5]: {
      id: backgrounds_enum.base_tiles_5,
      image: prefixes[DLC.BaseGame] + "/tiles_5.png",
      icon: prefixes[DLC.BaseGame] + "/tiles_5_icon.png",
    },
    [backgrounds_enum.base_tiles_6]: {
      id: backgrounds_enum.base_tiles_6,
      image: prefixes[DLC.BaseGame] + "/tiles_6.png",
      icon: prefixes[DLC.BaseGame] + "/tiles_6_icon.png",
    },
  },
  [DLC.AbyssalTerrors]: {
    [backgrounds_enum.bg_abyss]: {
      id: backgrounds_enum.bg_abyss,
      image: prefixes[DLC.AbyssalTerrors] + "/tiles_0.png",
      icon: prefixes[DLC.AbyssalTerrors] + "/bg_abyss_icon.png",
    },
    [backgrounds_enum.bg_ocean]: {
      id: backgrounds_enum.bg_ocean,
      image: prefixes[DLC.AbyssalTerrors] + "/tiles_1.png",
      icon: prefixes[DLC.AbyssalTerrors] + "/bg_ocean_icon.png",
    },
    [backgrounds_enum.bg_shipwreck]: {
      id: backgrounds_enum.bg_shipwreck,
      image: prefixes[DLC.AbyssalTerrors] + "/tiles_2.png",
      icon: prefixes[DLC.AbyssalTerrors] + "/bg_shipwreck_icon.png",
    },
  },
};

export { backgrounds };
