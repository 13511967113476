import { CATEGORY, ITEM_OBJECT } from "../../../../types/AppearanceTypes";

export const characters: { [key: string]: ITEM_OBJECT } = {
  buccaneer: {
    id: "buccaneer",
    name: "Buccaneer",
    icon: "assets/abyssal_terrors/buccaneer_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/buccaneer_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "buccaneer_eyes",
        name: "Buccaneer Eyes",
      },
      {
        path: "assets/abyssal_terrors/buccaneer_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "buccaneer_mouth",
        name: "Buccaneer Mouth",
      },
      {
        path: "assets/abyssal_terrors/buccaneer_app_2.png",
        depth: 1000.0,
        position: 4,
        priority: 3,
        id: "buccaneer_hat",
        name: "Buccaneer Hat",
      },
    ],
  },
  builder: {
    id: "builder",
    name: "Builder",
    icon: "assets/abyssal_terrors/builder_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/builder_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "builder_eyes",
        name: "Builder Eyes",
      },
      {
        path: "assets/abyssal_terrors/builder_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "builder_mouth",
        name: "Builder Mouth",
      },
      {
        path: "assets/abyssal_terrors/builder_app_2.png",
        depth: 1000.0,
        position: 4,
        priority: 2,
        id: "builder_hat",
        name: "Builder Hat",
      },
    ],
  },
  captain: {
    id: "captain",
    name: "Captain",
    icon: "assets/abyssal_terrors/captain_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/captain_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "captain_eyes",
        name: "Captain Eyes",
      },
      {
        path: "assets/abyssal_terrors/captain_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "captain_mouth",
        name: "Captain Mouth",
      },
      {
        path: "assets/abyssal_terrors/captain_app_2.png",
        depth: 1000.0,
        position: 4,
        priority: 3,
        id: "captain_hat",
        name: "Captain Hat",
      },
    ],
  },
  chef: {
    id: "chef",
    name: "Chef",
    icon: "assets/abyssal_terrors/chef_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/chef_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 2,
        id: "chef_eyes",
        name: "Chef Eyes",
      },
      {
        path: "assets/abyssal_terrors/chef_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 2,
        id: "chef_mouth",
        name: "Chef Mouth",
      },
      {
        path: "assets/abyssal_terrors/chef_app_2.png",
        depth: 1000.0,
        position: 4,
        priority: 2,
        id: "chef_hat",
        name: "Chef Hat",
      },
    ],
  },
  creature: {
    id: "creature",
    name: "Creature",
    icon: "assets/abyssal_terrors/creature_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/creature_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "creature_eyes",
        name: "Creature Eyes",
      },
      {
        path: "assets/abyssal_terrors/creature_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "creature_mouth",
        name: "Creature Mouth",
      },
    ],
  },
  curious: {
    id: "curious",
    name: "Curious",
    icon: "assets/abyssal_terrors/curious_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/curious_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "curious_eyes",
        name: "Curious Eyes",
      },
      {
        path: "assets/abyssal_terrors/curious_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "curious_mouth",
        name: "Curious Mouth",
      },
    ],
  },
  diver: {
    id: "diver",
    name: "Diver",
    icon: "assets/abyssal_terrors/diver_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/diver_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "diver_eyes",
        name: "Diver Eyes",
      },
      {
        path: "assets/abyssal_terrors/diver_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "diver_mouth",
        name: "Diver Mouth",
      },
      {
        path: "assets/abyssal_terrors/diver_app_2.png",
        depth: 50.0,
        position: 0,
        priority: 2,
        id: "diver_back",
        name: "Diver Back",
      },
    ],
  },
  druid: {
    id: "druid",
    name: "Druid",
    icon: "assets/abyssal_terrors/druid_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/druid_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "druid_eyes",
        name: "Druid Eyes",
      },
      {
        path: "assets/abyssal_terrors/druid_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "druid_mouth",
        name: "Druid Mouth",
      },
      {
        path: "assets/abyssal_terrors/druid_app_2.png",
        depth: 1000.0,
        position: 4,
        priority: 2,
        id: "druid_hat",
        name: "Druid Hat",
      },
    ],
  },
  dwarf: {
    id: "dwarf",
    name: "Dwarf",
    icon: "assets/abyssal_terrors/dwarf_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/dwarf_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "dwarf_eyes",
        name: "Dwarf Eyes",
      },
      {
        path: "assets/abyssal_terrors/dwarf_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "dwarf_mouth",
        name: "Dwarf Mouth",
      },
      {
        path: "assets/abyssal_terrors/dwarf_app_2.png",
        depth: 620.0,
        position: 7,
        priority: 0,
        id: "dwarf_nose",
        name: "Dwarf Nose",
      },
    ],
  },
  gangster: {
    id: "gangster",
    name: "Gangster",
    icon: "assets/abyssal_terrors/gangster_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/gangster_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "gangster_eyes",
        name: "Gangster Eyes",
      },
      {
        path: "assets/abyssal_terrors/gangster_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "gangster_mouth",
        name: "Gangster Mouth",
      },
      {
        path: "assets/abyssal_terrors/gangster_app_2.png",
        depth: 1000.0,
        position: 4,
        priority: 2,
        id: "gangster_hat",
        name: "Gangster Hat",
      },
    ],
  },
  hiker: {
    id: "hiker",
    name: "Hiker",
    icon: "assets/abyssal_terrors/hiker_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/hiker_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "hiker_eyes",
        name: "Hiker Eyes",
      },
      {
        path: "assets/abyssal_terrors/hiker_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "hiker_mouth",
        name: "Hiker Mouth",
      },
      {
        path: "assets/abyssal_terrors/hiker_app_2.png",
        depth: 800.0,
        position: 4,
        priority: 1,
        id: "hiker_hat",
        name: "Hiker Hat",
      },
      {
        path: "assets/abyssal_terrors/hiker_app_3.png",
        depth: -500.0,
        position: 9,
        priority: 2,
        id: "hiker_back",
        name: "Hiker Back",
      },
    ],
  },
  ogre: {
    id: "ogre",
    name: "Ogre",
    icon: "assets/abyssal_terrors/ogre_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/ogre_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "ogre_eyes",
        name: "Ogre Eyes",
      },
      {
        path: "assets/abyssal_terrors/ogre_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "ogre_mouth",
        name: "Ogre Mouth",
      },
    ],
  },
  romantic: {
    id: "romantic",
    name: "Romantic",
    icon: "assets/abyssal_terrors/romantic_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/romantic_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "romantic_eyes",
        name: "Romantic Eyes",
      },
      {
        path: "assets/abyssal_terrors/romantic_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "romantic_mouth",
        name: "Romantic Mouth",
      },
    ],
  },
  sailor: {
    id: "sailor",
    name: "Sailor",
    icon: "assets/abyssal_terrors/sailor_icon.png",
    category: CATEGORY.CHARACTER,
    appearance: [
      {
        path: "assets/abyssal_terrors/sailor_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 0,
        id: "sailor_eyes",
        name: "Sailor Eyes",
      },
      {
        path: "assets/abyssal_terrors/sailor_app_1.png",
        depth: 250.0,
        position: 8,
        priority: 0,
        id: "sailor_mouth",
        name: "Sailor Mouth",
      },
      {
        path: "assets/abyssal_terrors/sailor_app_2.png",
        depth: 400.0,
        position: 10,
        priority: 1,
        id: "sailor_shirt",
        name: "Sailor Shirt",
      },
      {
        path: "assets/abyssal_terrors/sailor_app_3.png",
        depth: 800.0,
        position: 4,
        priority: 1,
        id: "sailor_hat",
        name: "Sailor Hat",
      },
    ],
  },
};
