import "./ToggleButton.scss";
import { AudioManager } from "../../lib/audio/audioManager";
import { useEffect, useState } from "react";

function ToggleButton({
  value = false,
  label = "",
  onValueChange = () => {},
  ...props
}: {
  value: boolean;
  label?: string;
  onValueChange?: Function;
  [key: string]: any;
}) {
  const [_value, _setValue] = useState(value);

  useEffect(() => {
    _setValue(value);
  }, [value]);

  return (
    <div
      onClick={() => {
        onValueChange(!_value);
        AudioManager.click();
      }}
      className="toggle-button-container round-container"
      style={{}}
      {...props}
    >
      <div className={`toggle-button ${_value ? "active" : ""}`}>
        <div className="inner-toggle-button"></div>
      </div>
      <span>{label}</span>
      {props.children}
    </div>
  );
}

export default ToggleButton;
