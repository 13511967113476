import { CATEGORY, ITEM_OBJECT } from "../../../../types/AppearanceTypes";

export const items: { [key: string]: ITEM_OBJECT } = {
  thumb_up: {
    id: "thumb_up",
    name: "Thumb Up",
    icon: "assets/cosmetics/thumb_up.png",
    category: CATEGORY.COSMETIC,
    appearance: {
      path: "assets/cosmetics/thumb_up.png",
      depth: 2000,
      position: 8,
      priority: 1,
    },
  },
  thumb_down: {
    id: "thumb_down",
    name: "Thumb Down",
    icon: "assets/cosmetics/thumb_down.png",
    category: CATEGORY.COSMETIC,
    appearance: {
      path: "assets/cosmetics/thumb_down.png",
      depth: 2000,
      position: 8,
      priority: 1,
    },
  },
  grinning_face_with_sweat: {
    id: "grinning_face_with_sweat",
    name: "Grinning Face with Sweat",
    icon: "assets/cosmetics/grinning_face_with_sweat.png",
    category: CATEGORY.COSMETIC,
    appearance: {
      path: "assets/cosmetics/grinning_face_with_sweat.png",
      depth: 2000,
      position: 8,
      priority: 1,
    },
  },
  face_with_tears_of_joy: {
    id: "face_with_tears_of_joy",
    name: "Face with Tears of Joy",
    icon: "assets/cosmetics/face_with_tears_of_joy.png",
    category: CATEGORY.COSMETIC,
    appearance: {
      path: "assets/cosmetics/face_with_tears_of_joy.png",
      depth: 2000,
      position: 8,
      priority: 1,
    },
  },
  smiling_face_with_hearts: {
    id: "smiling_face_with_hearts",
    name: "Smiling Face with Hearts",
    icon: "assets/cosmetics/smiling_face_with_hearts.png",
    category: CATEGORY.COSMETIC,
    appearance: {
      path: "assets/cosmetics/smiling_face_with_hearts.png",
      depth: 2000,
      position: 8,
      priority: 1,
    },
  },
  face_in_clouds: {
    id: "face_in_clouds",
    name: "Face in Clouds",
    icon: "assets/cosmetics/face_in_clouds.png",
    category: CATEGORY.COSMETIC,
    appearance: {
      path: "assets/cosmetics/face_in_clouds.png",
      depth: 2200,
      position: 8,
      priority: 1,
    },
  },
};
