import "./MainContainer.scss";

import { useState } from "react";
import Settings from "../Settings/Settings";
import { ReactComponent as ShuffleIcon } from "../../icons/shuffle.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { ReactComponent as CopyIcon } from "../../icons/copy.svg";
import { ReactComponent as ClearIcon } from "../../icons/clear.svg";
import { ReactComponent as UndoIcon } from "../../icons/undo.svg";
import { ReactComponent as RedoIcon } from "../../icons/redo.svg";
import { ItemManager } from "../../lib/appearance/item_manager";
import { AudioManager } from "../../lib/audio/audioManager";
import { useDispatch } from "react-redux";
import { redo, undo } from "../../store/avatar";
import ScrollSelector from "../ScrollSelector/ScrollSelector";
import ItemsContainer from "./ItemsContainer/ItemsContainer";
import BackgroundContainer from "./BackgroundContainer/BackgroundContainer";
import CosmeticContainer from "./CosmeticContainer/CosmeticContainer";

const SETTINGS_INDEX = -10;
const ITEMS_INDEX = 0;
const COSMETIC_INDEX = 1;
const BACKGROUND_INDEX = 2;

function MainContainer({
  className = "",
  selected = [],
  onShuffle = (keys: string[]) => {},
  onClear = () => {},
  onDownload = () => {},
  onClipbaord = () => {},
}: {
  className?: string;
  group?: { name: string; items: any[] };
  filter?: string;
  selected?: string[];
  onClick?: Function;
  onShuffle?: Function;
  onClear?: Function;
  onDownload?: Function;
  onClipbaord?: Function;
}) {
  const dispatch = useDispatch();

  //tab controll state for active tab
  const [activeTab, setActiveTab] = useState(ITEMS_INDEX);

  const renderTabContent = (index: number): JSX.Element => {
    switch (index) {
      case SETTINGS_INDEX:
        return renderSettingsContent();
      case ITEMS_INDEX:
        return <ItemsContainer selected={selected} />;
      case COSMETIC_INDEX:
        return <CosmeticContainer selected={selected} />;
      case BACKGROUND_INDEX:
        return <BackgroundContainer />;
      default:
        return <></>;
    }
  };

  const renderSettingsContent = (): JSX.Element => {
    return <Settings />;
  };

  return (
    <div className={`main-container ${className}`}>
      <ScrollSelector
        items={[
          { key: "items", value: "Items", index: ITEMS_INDEX },
          { key: "cosmetics", value: "Cosmetics", index: COSMETIC_INDEX },
          {
            key: "background",
            value: "Background",
            index: BACKGROUND_INDEX,
          },
          {
            key: "settings",
            value: "Settings",
            index: SETTINGS_INDEX,
          },
        ]}
        selected={activeTab}
        onSelect={(key: any) => {
          AudioManager.click();
          setActiveTab(key.index);
        }}
      />
      <div className="main-container-content">
        {renderTabContent(activeTab)}
      </div>
      <div className="bottom-bar">
        <div className="buttons-container">
          <div
            onClick={() => {
              AudioManager.click();
              onClipbaord();
            }}
            className={"clipboard round-interactive"}
          >
            <CopyIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              onDownload();
            }}
            className={"download round-interactive"}
          >
            <DownloadIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              onClear();
            }}
            className={"clear round-interactive"}
          >
            <ClearIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              dispatch(undo());
            }}
            className={"undo round-interactive"}
          >
            <UndoIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              dispatch(redo());
            }}
            className={"redo round-interactive"}
          >
            <RedoIcon />
          </div>
          <div
            onClick={() => {
              AudioManager.click();
              onShuffle(ItemManager.instance.shuffle());
            }}
            className={"shuffle round-interactive"}
          >
            <ShuffleIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainContainer;
