import { createSlice } from "@reduxjs/toolkit";
import { ItemManager } from "../lib/appearance/item_manager";
import { DLC } from "../types/GameTypes";
import { BackgroundObject } from "../lib/background/backgrounds";

export enum ItemMode {
  Equiped = "equiped",
  Item = "item",
}
export enum DockingMode {
  Bottom = "bottom",
  Left = "left",
}

export interface SettingsState {
  showLabels: boolean;
  showLegs: boolean;
  showAnimation: boolean;
  docking: DockingMode;
  itemMode: ItemMode;
  audio: boolean;
  showWeapons: boolean;
  showShadow: boolean;
  background: BackgroundObject | null;
  dlcFilter: string[];
}

const initialState = {
  showLabels: true,
  showLegs: true,
  showAnimation: true,
  itemMode: ItemMode.Equiped,
  audio: false,
  showWeapons: false,
  showShadow: true,
  background: null,
  docking: DockingMode.Bottom,
  dlcFilter: [DLC.BaseGame, DLC.AbyssalTerrors],
} satisfies SettingsState as SettingsState;

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setShowLabels: (state, action) => {
      state.showLabels = action.payload;
    },
    setShowLegs: (state, action) => {
      state.showLegs = action.payload;
    },
    setShowAnimation: (state, action) => {
      state.showAnimation = action.payload;
    },
    setItemMode: (state, action) => {
      state.itemMode = action.payload;
    },
    setAudio: (state, action) => {
      state.audio = action.payload;
    },
    setShowWeapons: (state, action) => {
      state.showWeapons = action.payload;
    },
    setShowShadow: (state, action) => {
      state.showShadow = action.payload;
    },
    setBackground: (state, action) => {
      state.background = action.payload;
    },
    setDLCFilter: (state, action) => {
      state.dlcFilter = action.payload;
      ItemManager.instance.setItems(action.payload);
    },
    changeDocking: (state, action) => {
      state.docking = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setItemMode,
  setShowAnimation,
  setShowLabels,
  setShowLegs,
  setAudio,
  setShowWeapons,
  setShowShadow,
  setBackground,
  setDLCFilter,
  changeDocking,
} = settingsSlice.actions;

export default settingsSlice.reducer;
