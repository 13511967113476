import { CATEGORY, ITEM_OBJECT } from "../../../../types/AppearanceTypes";

export const items: { [key: string]: ITEM_OBJECT } = {
  ashes: {
    id: "ashes",
    name: "Ashes",
    icon: "assets/abyssal_terrors/ashes.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/ashes_app_0.png",
        depth: 25.0,
        position: 0,
        priority: 3,
      },
    ],
  },
  axolotl: {
    id: "axolotl",
    name: "Axolotl",
    icon: "assets/abyssal_terrors/axolotl.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/axolotl_app_0.png",
        depth: 700.0,
        position: 16,
        priority: 3,
      },
      {
        path: "assets/abyssal_terrors/axolotl_app_1.png",
        depth: -80.0,
        position: 17,
        priority: 3,
      },
    ],
  },
  baby_squid: {
    id: "baby_squid",
    name: "Baby Squid",
    icon: "assets/abyssal_terrors/baby_squid.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/baby_squid_app_0.png",
        depth: 550.0,
        position: 8,
        priority: 2,
      },
    ],
  },
  barnacle: {
    id: "barnacle",
    name: "Barnacle",
    icon: "assets/abyssal_terrors/barnacle.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/barnacle_app_0.png",
        depth: 1000.0,
        position: 4,
        priority: 2,
      },
    ],
  },
  black_flag: {
    id: "black_flag",
    name: "Black Flag",
    icon: "assets/abyssal_terrors/black_flag.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/black_flag_app_0.png",
        depth: 1300.0,
        position: 1,
        priority: 2,
      },
    ],
  },
  cauldron: {
    id: "cauldron",
    name: "Cauldron",
    icon: "assets/abyssal_terrors/cauldron.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/cauldron_app_0.png",
        depth: 600.0,
        position: 16,
        priority: 1,
      },
      {
        path: "assets/abyssal_terrors/cauldron_app_1.png",
        depth: -200.0,
        position: 17,
        priority: 1,
      },
    ],
  },
  coral: {
    id: "coral",
    name: "Coral",
    icon: "assets/abyssal_terrors/coral.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/coral_app_0.png",
        depth: 600.0,
        position: 2,
        priority: 2,
      },
    ],
  },
  corrupted_shard: {
    id: "corrupted_shard",
    name: "Corrupted Shard",
    icon: "assets/abyssal_terrors/corrupted_shard.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/corrupted_shard_app_0.png",
        depth: 600.0,
        position: 2,
        priority: 2,
      },
    ],
  },
  crystal: {
    id: "crystal",
    name: "Crystal",
    icon: "assets/abyssal_terrors/crystal.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/crystal_app_0.png",
        depth: 1.0,
        position: 14,
        priority: 3,
      },
    ],
  },
  eyepatch: {
    id: "eyepatch",
    name: "Eyepatch",
    icon: "assets/abyssal_terrors/eyepatch.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/eyepatch_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 2,
      },
    ],
  },
  feather: {
    id: "feather",
    name: "Feather",
    icon: "assets/abyssal_terrors/feather.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/feather_app_0.png",
        depth: 1300.0,
        position: 1,
        priority: 2,
      },
    ],
  },
  fish_hook: {
    id: "fish_hook",
    name: "Fish Hook",
    icon: "assets/abyssal_terrors/fish_hook.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/fish_hook_app_0.png",
        depth: 80.0,
        position: 5,
        priority: 2,
      },
      {
        path: "assets/abyssal_terrors/fish_hook_app_1.png",
        depth: -1100.0,
        position: 15,
        priority: 2,
      },
    ],
  },
  goblet: {
    id: "goblet",
    name: "Goblet",
    icon: "assets/abyssal_terrors/goblet.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/goblet_app_0.png",
        depth: 250.0,
        position: 8,
        priority: 2,
      },
    ],
  },
  jerky: {
    id: "jerky",
    name: "Jerky",
    icon: "assets/abyssal_terrors/jerky.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/jerky_app_0.png",
        depth: 250.0,
        position: 8,
        priority: 2,
      },
    ],
  },
  knot: {
    id: "knot",
    name: "Knot",
    icon: "assets/abyssal_terrors/knot.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/knot_app_0.png",
        depth: 401.0,
        position: 10,
        priority: 3,
      },
    ],
  },
  krakens_eye: {
    id: "krakens_eye",
    name: "Krakens Eye",
    icon: "assets/abyssal_terrors/krakens_eye.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/krakens_eye_app_0.png",
        depth: 500.0,
        position: 6,
        priority: 3,
      },
    ],
  },
  lantern: {
    id: "lantern",
    name: "Lantern",
    icon: "assets/abyssal_terrors/lantern.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/lantern_app_0.png",
        depth: 1300.0,
        position: 1,
        priority: 4,
      },
    ],
  },
  lighthouse: {
    id: "lighthouse",
    name: "Lighthouse",
    icon: "assets/abyssal_terrors/lighthouse.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/lighthouse_app_0.png",
        depth: 5.0,
        position: 0,
        priority: 2,
      },
    ],
  },
  mirror: {
    id: "mirror",
    name: "Mirror",
    icon: "assets/abyssal_terrors/mirror.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/mirror_app_0.png",
        depth: 1350.0,
        position: 4,
        priority: 1,
      },
    ],
  },
  pearl: {
    id: "pearl",
    name: "Pearl",
    icon: "assets/abyssal_terrors/pearl.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/pearl_app_0.png",
        depth: 50.0,
        position: 5,
        priority: 2,
      },
    ],
  },
  penguin: {
    id: "penguin",
    name: "Penguin",
    icon: "assets/abyssal_terrors/penguin.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/penguin_app_0.png",
        depth: 800.0,
        position: 4,
        priority: 1,
      },
      {
        path: "assets/abyssal_terrors/penguin_app_1.png",
        depth: -80.0,
        position: 17,
        priority: 1,
      },
    ],
  },
  scarf: {
    id: "scarf",
    name: "Scarf",
    icon: "assets/abyssal_terrors/scarf.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/scarf_app_0.png",
        depth: 401.0,
        position: 10,
        priority: 3,
      },
    ],
  },
  seashell: {
    id: "seashell",
    name: "Seashell",
    icon: "assets/abyssal_terrors/seashell.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/seashell_app_0.png",
        depth: -501.0,
        position: 9,
        priority: 3,
      },
    ],
  },
  small_fish: {
    id: "small_fish",
    name: "Small Fish",
    icon: "assets/abyssal_terrors/small_fish.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/small_fish_app_0.png",
        depth: 700.0,
        position: 1,
        priority: 1,
      },
    ],
  },
  spyglass: {
    id: "spyglass",
    name: "Spyglass",
    icon: "assets/abyssal_terrors/spyglass.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/spyglass_app_0.png",
        depth: -500.0,
        position: 9,
        priority: 1,
      },
      {
        path: "assets/abyssal_terrors/spyglass_app_1.png",
        depth: 500.0,
        position: 10,
        priority: 1,
      },
    ],
  },
  starfish: {
    id: "starfish",
    name: "Starfish",
    icon: "assets/abyssal_terrors/starfish.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/starfish_app_0.png",
        depth: 700.0,
        position: 7,
        priority: 3,
      },
    ],
  },
  sunken_bell: {
    id: "sunken_bell",
    name: "Sunken Bell",
    icon: "assets/abyssal_terrors/sunken_bell.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/sunken_bell_app_0.png",
        depth: 1000.0,
        position: 4,
        priority: 4,
      },
    ],
  },
  whistle: {
    id: "whistle",
    name: "Whistle",
    icon: "assets/abyssal_terrors/whistle.png",
    category: CATEGORY.ITEM,
    appearance: [
      {
        path: "assets/abyssal_terrors/whistle_app_0.png",
        depth: 250.0,
        position: 8,
        priority: 1,
      },
    ],
  },
};
