import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";

import settings from "./settings";
import avatar from "./avatar";

const middleware = createListenerMiddleware();
// middleware.startListening({
//   //   actionCreator: avatarSlice.actions.addAppearance,
//   //   effect: async (action, store: any) => {
//   //     console.log("action", action, store);
//   //     store.dispatch(addWithoutSave(store.getState().avatar.appearance));
//   //   },
// });

export default configureStore({
  reducer: {
    settings,
    avatar,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(middleware.middleware),
});
