import "./ButtonGroup.scss";
import { AudioManager } from "../../lib/audio/audioManager";
import { useEffect, useState } from "react";

function ButtonGroup({
  values = [],
  selected = undefined,
  multiple = false,
  fallback = values[0],
  onClick = () => {},
  onRender = (key: string) => {
    return <div>{key}</div>;
  },
  onChange = () => {},
  ...props
}: {
  values: string[];
  multiple?: boolean;
  fallback?: string;
  selected?: string | string[] | undefined;
  onClick?: Function;
  onChange?: Function;
  onRender?: Function;
  [key: string]: any;
}) {
  const [_selected, _setSelected] = useState<string[]>(
    Array.isArray(selected) ? selected : [selected ?? values[0]]
  );

  const setSelected = (key: string) => {
    //with fallback for multiple when empty
    if (multiple) {
      if (_selected.includes(key)) {
        const _new_setSelected = _selected.filter((k) => k !== key);
        if (_new_setSelected.length === 0) {
          _setSelected([fallback]);
          onChange([fallback]);
        } else {
          _setSelected(_new_setSelected);
          onChange(_new_setSelected);
        }
      } else {
        _setSelected([..._selected, key]);
        onChange([..._selected, key]);
      }
    } else {
      _setSelected([key]);
      onChange([key]);
    }
  };

  useEffect(() => {
    if (selected) {
      _setSelected(Array.isArray(selected) ? selected : [selected as string]);
    }
  }, [selected]);

  return (
    <div
      onClick={() => {
        AudioManager.click();
      }}
      className="button-group-container round-container"
      style={{}}
      {...props}
    >
      {values.map((key, index) => {
        return (
          <div
            className={`button-group-button ${key} ${
              _selected.includes(key) ? "selected" : ""
            } `}
            key={index}
            onClick={() => {
              setSelected(key);
              onClick(key);
            }}
          >
            {onRender(key)}
          </div>
        );
      })}
    </div>
  );
}

export default ButtonGroup;
